.animalItem {
  background-color: #fff;
  padding: 10px;
  margin: 9px 0px;
  display: block;
  position: relative;
  cursor: pointer;
  box-shadow: 1px 1px 3px #80808050;
}

.animalItem .image img {
  object-fit: cover;
}

.animalItem:hover .image img {
  transform: scale(1.25);
}

@media (max-width: 1400px) {
  .animalItem {
    padding: 9px;
  }
}

@media (max-width: 576px) {
  .animalItem {
    display: flex;
    padding: 9px;
    padding-right: 0 !important;
  }
}

.image {
  position: relative;
  overflow: hidden;
}

@media (max-width: 576px) {
  .image {
    width: 44%;
  }

  .mobileContent {
    width: 53%;
    margin-left: 3%;
  }
}

.image .ratio {
  object-fit: cover;
}

.image img {
  transition: transform 0.4s ease-in-out;
}

.genderTag {
  font-family: 'Noto Sans JP', sans-serif;
  width: fit-content;
  padding: 2px 8px;
  margin: 10px 0px;
  color: #fff;
  font-size: 0.85rem;
}

@media (max-width: 576px) {
  .genderTag {
    margin: 3px 0px;
    margin-top: 0 !important;
    font-size: 0.75rem;
    height: min-content;
  }

}

.parentTag {
  font-family: 'Noto Sans JP', sans-serif;
  width: fit-content;
  padding: 3px 8px;
  margin: 10px 0px;
  color: #fff;
  font-size: 0.8rem;
  background-color: var(--cmn-chartreuse);
}

@media (max-width: 576px) {
  .parentTag {
    margin: 3px 0px;
    margin-top: 0 !important;
    padding: 2px 8px;
    font-size: 0.75rem;
    height: min-content;
  }
}

.maleTag {
  background-color: var(--cmn-sky-blue);
}

.femaleTag {
  background-color: var(--cmn-dusty-rose);
}

.animalType {
  font-family: 'Noto Sans JP', sans-serif;
  color: var(--cmn-text-color-ash);
  margin-bottom: 3px !important;
  font-weight: bold !important
}

@media (max-width: 1400px) {
  .animalType {
    font-size: 0.9rem !important;
  }
}

@media (max-width: 576px) {
  .animalType {
    font-size: 0.8rem !important;
  }
}

.birthday {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 0.8rem;
  margin-bottom: 5px;
}

@media (max-width: 1400px) {
  .birthday {
    font-size: 0.75rem !important;
  }
}

@media (max-width: 576px) {
  .birthday {
    font-size: 0.7rem !important;
  }
}

.type {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  font-size: 0.8rem;
  color: var(--cmn-text-color-ash);
  display: flex;
  align-items: center;
  white-space: nowrap;
}

@media (max-width: 1400px) {
  .type {
    font-size: 0.7rem !important;
  }
}

@media (max-width: 576px) {
  .type {
    font-size: 0.78rem !important;
    white-space: nowrap;
  }
}

.data {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 0.8rem;
  padding: 0 !important;
  color: var(--cmn-text-color-ash);
  padding-left: 5px !important;
}

@media (max-width: 1400px) {
  .data {
    font-size: 0.75rem;
  }
}

@media (max-width: 576px) {
  .data {
    font-size: 0.78rem;
    padding-left: 3px !important;
  }
}

.priceRowPadding {
  padding-top: 10px !important;
}

.price {
  font-size: 1.01rem;
  font-weight: bold;
  white-space: nowrap;
  padding-top: 8px !important;
}

@media (max-width: 1400px) {
  .price {
    font-size: 0.9rem;
    font-weight: bold;

  }
}

@media (max-width: 576px) {
  .priceRowPadding {
    padding-top: 5px !important;
  }

  .price {
    font-size: 0.9rem;
    font-weight: bold;
    padding-top: 3px !important;
  }
}

.hot {
  background-color: var(--cmn-red);
  color: #fff;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  position: absolute;
  text-transform: uppercase;
  padding: 3px 15px;
  top: 5%;
  left: 0;
  font-size: 0.8rem;
}

@media (max-width: 576px) {
  .hot {
    font-size: 0.75rem;
    padding: 1px 10px;
    top: 12%;
  }
}

.new {
  background-color: var(--cmn-mango-orange);
  color: #fff;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  position: absolute;
  text-transform: uppercase;
  padding: 3px 15px;
  top: 5%;
  left: 0;
  font-size: 0.8rem;
}

@media (max-width: 576px) {
  .new {
    font-size: 0.75rem;
    padding: 1px 10px;
    top: 12%;
  }
}

.favIcon {
  cursor: pointer;
  transform: scale(1);
  transition: all 0.2s ease-in;
  color: var(--cmn-silver);
  position: absolute;
  bottom: 10px;
  right: 10px;
}

@media (max-width: 576px) {
  .favIcon {
    bottom: 7px;
    right: 10px;
  }
}

.favIcon:hover {
  transform: scale(1.2);
  transition: transform 0.2s ease-in;
  color: #626262;
}

.favAtTop {
  position: absolute;
  top: 22%;
  right: 5%;
}

.mobileLongCardContent {
  width: 200px !important;
  flex-direction: column;
  padding: 8px !important;
  padding-bottom: 30px !important;
}

.mobileLongCardContent .image {
  width: 100%;
}

.mobileLongCardContent .mobileContent {
  width: 100%;
  margin-left: 0 !important;
  margin-top: 7px !important;
}

.mobileLongCardContent .favAtTop {
  position: relative;
  margin-top: 0px;
  margin-bottom: 7px;
}

.mobileLongCardContent .new .mobileLongCardContent .hot {
  top: 6%;
}

.mobileCardContent {
  width: 100%;
}

.gridTypeShow {
  width: 100% !important;
}

.gridTypeShow .data {
  font-size: 0.76rem;
}

.mobileFavCard {
  top: 10px;
  /* bottom: 100%; */
}