.button {
  font-family: var(--AppFont);
  color: var(--cmn-text-color-ash);
  font-weight: 500;
}

@media (max-width: 576px) {
  .button {
    font-family: var(--AppFont);
    font-size: 0.85rem;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.count {
  font-family: var(--AppFont);
  color: var(--cmn-text-color-ash);
  font-weight: 500;
}

@media (max-width: 576px) {
  .count {
    font-size: 0.85rem;
  }
}