.link {
  color: var(--cmn-text-color-dark);
  margin: 0 20px;
  text-decoration: none;
  position: relative;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 7px;
  cursor: pointer;
  /* transition: all 0.1s ease; */
}

@media (max-width: 576px) {
  .link {
    margin: 0 14px;
  }
}

.fixedType .link {
  margin: 0 20px;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown .link {
  padding-right: 5px;
}

.dropdown::after {
  content: "";
  width: 20px;
  height: 20px;
  background-image: url(../../../../public/assets/img/bottomArrow.png);
  background-repeat: no-repeat;
  background-position: center;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  right: 0px;
  position: absolute;
  transition: transform 0.3s ease;
}


.arrowUp::after {
  transform: rotate(180deg);
}



.dropdownList {
  display: none;
  position: absolute;
  left: 10%;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  position: absolute;
  top: 30px;
  left: 10%;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  width: -webkit-fill-available;
  white-space: nowrap;
}

.dropdownList.open {
  display: block;
  opacity: 1;
  pointer-events: auto;
}

/* Dropdown Item style */
.dropdownList div {
  margin: 10px 0px;
}

.dropdownList div a {
  text-decoration: none;
  font-family: var(--AppFont);
  color: var(--cmn-text-color-dark);
  position: relative;
}

.dropdownList div a:after {
  content: "";
  position: absolute;
  left: -1px;
  bottom: -5px;
  width: 0;
  height: 0px;
  background-color: black;
  transition: width 0.3s ease-in-out;
}

.dropdownList div a:hover::after {
  width: 100%;
  height: 2px;
}

.dropdownList div p {
  font-family: var(--AppFont);
  color: var(--cmn-text-color-dark);

}

.link:hover {
  padding-bottom: 11px;
  border-bottom: 2px solid var(--cmn-text-color-dark);
}

@media (max-width: 576px) {
  .link:hover {
    padding: 0;
    border: none;
  }

  .link:hover::after {
    padding-bottom: 7px;
    content: "";
    position: absolute;
    border-bottom: 2px solid var(--cmn-text-color-dark);
    width: 100%;
    left: 0;
    bottom: -10px;
  }
}

.fixedType .link:hover {
  margin: 0 20px;
  padding-bottom: 4px;
  border-bottom: 1px solid black !important;
}

.mobileLinkContainer {}

@media (max-width: 576px) {
  .mobileLinkContainer {
    width: 100%;
    overflow-x: scroll;
    display: flex;
    white-space: nowrap;
    align-items: center;
  }

  .link {
    padding: 7px 0px;
    font-size: 0.85rem;
    color: #6B6B6B;
    position: relative;
  }

  .active {
    color: var(--cmn-text-color-dark);
  }

  .mobileLinkContainer::-webkit-scrollbar {
    display: none;
  }
}