.FixedHeader {
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 3px;
  width: 100%;
  background-color: #fff;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.3));
  display: block;
  position: fixed;
  top: 0;
  z-index: 999;
  animation: comeFromTop 0.3s 1;
}

.title {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 1.5rem !important;
  color: var(--cmn-text-color-dark) !important;
  font-weight: bold;
  text-decoration: none;
}


@keyframes comeFromTop {
  from {
    top: -50px;
    opacity: 0;
  }

  to {
    top: 0px;
    opacity: 1;
  }
}