.mobile {
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0 !important;
  width: 100%;
  padding: 0 !important;
  margin-left: 0 I !important;
  margin-right: 0 I !important;
  margin-top: 100px !important;
}

.mobileRow {
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0 !important;
}

.grayArea {
  background-color: var(--cmn-gray-shade-100);
  padding-top: 3%;
  padding-bottom: 6%;
}

@media (max-width: 576px) {
  .grayArea {
    padding-top: 3%;
    padding-bottom: 6%;
  }
}