.loadMoreButton {
  background-color: var(--cmn-pastel-yellow) !important;
  --bs-btn-border-color: var(--cmn-pastel-yellow) !important;
  font-family: 'Noto Sans JP', sans-serif !important;
  color: var(--cmn-text-color-ash) !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  padding: 10px 70px !important;
  border-radius: 0 !important;
  transition: all 0.3s ease-in;
  border: none;
  outline: none;
}


@media (max-width: 576px) {
  .loadMoreButton {
    width: 100%;
  }
}

.loadMoreButton:hover {
  background-color: #fff !important;
  transform: scale(1.07);
  transition: all 0.3s ease-in;
  border: 1px solid var(--cmn-pastel-yellow);
  color: var(--cmn-pastel-yellow) !important;
}