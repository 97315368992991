.BG_GRAY {
  background-color: var(--cmn-gray-light);
  padding: 30px 0px;
}

.heading {
  font-family: var(--AppFont);
  font-weight: bold;
  padding-left: 7px;
  color: var(--cmn-text-color-dark);
  border-left: 4px solid var(--cmn-text-color-dark);
}

@media (max-width: 576px) {
  .heading {
    padding-left: 5px;
    border-left: 5px solid var(--cmn-text-color-dark);
    font-size: calc(1.1rem + .3vw);
    margin: 5% 0%;
  }
}

.history {
  background-color: #fff;
  padding: 2% 8%;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 576px) {
  .history {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.8% 4%;
  }
}

.history .list {
  width: 70%;
  border: 2px solid var(--cmn-text-color-ash);
  display: flex;
  padding: 6px 10px;
  flex-wrap: wrap;
}

@media (max-width: 576px) {
  .history div {
    width: 100% !important;
    margin-bottom: 10px;
  }
}

.history .list a {
  margin-right: 5px;
  text-decoration: none;
  border: none;
}

@media (max-width: 576px) {
  .history .list a {
    margin-right: 2px;
    font-size: 0.75rem;
  }
}


.history .list a::after {
  content: "/";
  margin-left: 5px;
  font-weight: 400 !important;
}

@media (max-width: 576px) {
  .history .list a::after {
    margin-left: 2px;
  }
}

.history .list a:last-child::after {
  content: "";
}

.history p {
  margin-bottom: 0 !important;
  font-family: var(--AppFont);
  color: var(--cmn-text-color-ash);
  font-weight: 500;
}

@media (max-width: 576px) {
  .history p {
    width: 100%;
    text-align: start;
    font-size: 0.8rem;
  }
}

.history a {
  margin-bottom: 0 !important;
  font-family: var(--AppFont);
  color: var(--cmn-text-color-ash);
  text-decoration: none;
  border-bottom: 2px solid var(--cmn-text-color-ash);
  font-weight: 500;
  white-space: nowrap;
}

@media (max-width: 576px) {
  .history a {
    font-size: 0.8rem;
  }

  .history .mMID {
    text-align: center;
  }
}

.searchBOX {
  background-color: var(--cmn-pastel-yellow);
  padding: 3% 7%;
  border-radius: 10px;
  margin: 30px 0px;
}

@media (max-width: 576px) {
  .searchBOX {
    border-radius: 0;
  }
}

.searchBOX form {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1400px) {
  .searchBOX {
    padding: 3% 6%;
  }
}

@media (max-width: 576px) {
  .searchBOX {
    padding: 3% 3%;
  }
}

.searchBOX .mainFilterOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 576px) {
  .searchBOX .mainFilterOptions {
    flex-wrap: wrap;
  }
}

.searchBOX .mainFilterOptions .type1,
.searchBOX .mainFilterOptions .type2 {
  width: 32%;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  box-shadow: 0px 0px 3px #888888;
}

@media (max-width: 576px) {

  .searchBOX .mainFilterOptions .type1,
  .searchBOX .mainFilterOptions .type2 {
    width: 100%;
    margin: 10px 0;
  }
}

.searchBOX .mainFilterOptions .type2 {
  padding: 6px 20px;
  padding-right: 0 !important;
}

.searchBOX .mainFilterOptions .type1 p,
.searchBOX .mainFilterOptions .type2 p {
  margin-bottom: 0 !important;
  font-family: var(--AppFont);
  white-space: nowrap;
}

.label {
  font-size: 0.8rem;
  color: var(--cmn-text-color-ash);
  margin-right: 3%;
  font-weight: bold;
  margin-bottom: 0 !important;
  white-space: nowrap;
}

@media (max-width: 1400px) {
  .label {
    margin-right: 1%;
  }
}

@media (max-width: 576px) {
  .label {
    margin-right: 2%;
  }
}


.searchBOX .mainFilterOptions .type1 .icon {
  font-size: 1.4rem;
  color: var(--cmn-gray-shade);
}

.searchBOX .mainFilterOptions .interectiveIcon .icon {
  font-size: 1.4rem;
  color: var(--cmn-slate-green);
}

.searchBOX .mainFilterOptions .type1 .icon:hover {
  color: var(--cmn-pastel-yellow);
}

.searchBOX .mainFilterOptions .type1 {
  cursor: pointer;
}

.secondaryLabel {
  font-size: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--cmn-gray-shade);
  margin-left: 2px;
  margin-bottom: 0 !important;
}



.searchBOX .mainFilterOptions .type1 .icon:last-child {
  color: var(--cmn-text-color-ash);
  margin-left: auto;
}

.searchBOX .mainFilterOptions .type1 .icon:last-child:hover {
  color: #3a53f2;
}


.searchBOX .mainFilterOptions .type2 .gender {
  box-shadow: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  width: auto;
  padding: 0 !important;
  margin-left: 11%;
}

.searchBOX .mainFilterOptions .type2 .gender div {
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--cmn-text-color-ash);
  padding: 6px 13px;
  margin: 0 7px;
  cursor: pointer;
  position: relative;
}


@media (max-width: 1400px) {
  .searchBOX .mainFilterOptions .type2 .gender div {
    padding: 6px 5px;
  }
}

.searchBOX .mainFilterOptions .type2 .gender div:first-child {
  margin-left: 0 !important;
}

.searchBOX .mainFilterOptions .type2 .gender div::after {
  content: "";
  height: 25px;
  width: 2px;
  right: -5px;
  position: absolute;
  background-color: var(--cmn-text-color-ash);
}

.searchBOX .mainFilterOptions .type2 .gender div:last-child::after {
  content: none;
}

.searchBOX .mainFilterOptions .type2 .gender div p {
  white-space: nowrap;
  font-size: 0.9rem;
  margin-bottom: 0 !important;
}

.searchBOX .mainFilterOptions .type2 .gender .active {
  background-color: #c8c8c8;
  padding: 6px 7px;
  box-shadow: 0px 0px 3px #DCDCDC;
}

@media (max-width: 576px) {
  .searchBOX .mainFilterOptions .type2 .gender .active {
    padding: 6px 10px;
  }
}

.searchBOX .secondaryFilterOption {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

@media (max-width: 576px) {
  .searchBOX .secondaryFilterOption {
    align-items: center;
    margin-top: 10px;
  }

}

.collapseLink {
  display: flex;
  text-decoration: none;
  align-items: center;
  margin-left: -1.5%;
}

.arrowIcon {
  color: var(--cmn-text-color-ash);
  text-decoration: none;
  font-size: 2.5rem !important;
}

.arrowIconRotate0 {
  transform: rotate(0deg);
  transition: all 0.3s ease !important;
}

.arrowIconRotate90 {
  transform: rotate(90deg);
  transition: all 0.3s ease !important;
}

.collapseLink p {
  font-family: var(--AppFont);
  color: var(--cmn-text-color-ash);
  font-weight: 500;
  font-size: 1rem !important;
  margin-bottom: 0 !important;
  margin-left: -1%;
}

.interectiveIcon {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-left: auto;
  margin-right: 2%;
}

.normalIcon {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--cmn-text-color-ash);
}

.hoveredIcon {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  color: var(--cmn-gray-shade);
  transition: opacity 0.3s ease-in-out;
}

.interectiveIcon:hover .normalIcon {
  opacity: 0;
}

.interectiveIcon:hover .hoveredIcon {
  opacity: 1;
}

.secondarySearchItems {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1%;
  justify-content: flex-start;
  align-items: center;
}

.secondarySearchItems .item1 {
  width: 22%;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  box-shadow: 0px 0px 3px #888888;
  min-width: 215px;
  margin-bottom: 30px;
  margin-right: 13px;
  height: 50px;
}

@media (max-width: 576px) {
  .secondarySearchItems .item1 {
    min-width: 52%;
    margin-bottom: 20px !important;
  }
}

.secondarySearchItems .item2,
.secondarySearchItems .item5,
.secondarySearchItems .item6 {
  width: 22%;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding-left: 20px;
  white-space: nowrap;
  min-width: 215px;
  margin-bottom: 30px;
  margin-right: 13px;
  height: 50px;
  box-shadow: 0px 0px 3px #888888;
}

.secondarySearchItems .item6 {
  width: 255px;
}


@media (max-width: 576px) {
  .secondarySearchItems .item2 {
    min-width: 43%;
    margin-bottom: 20px !important;
    margin-right: 0px !important;
  }

  .secondarySearchItems .item6 {
    width: 50%;
  }

  .mobileDatePicker {
    width: 70px;
  }
}

.secondarySearchItems .select {
  background-size: 16px 19px !important;
  background-position: 93%;
  outline: none !important;
  border: none !important;
  padding: 12px 21px;
  font-size: 0.9rem;
  color: var(--cmn-text-color-dark);
  padding-left: 10px;
}



.secondarySearchItems .select:focus {
  outline: none !important;
  box-shadow: none !important;
}

.secondarySearchItems .select option:disabled {
  color: var(--cmn-slate-green) !important;
}

.secondarySearchItems .item3,
.secondarySearchItems .item4 {
  width: 26%;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding-left: 20px;
  white-space: nowrap;
  min-width: 255px;
  margin-bottom: 30px;
  margin-right: 13px;
  height: 50px;
  box-shadow: 0px 0px 3px #888888;
}

.secondarySearchItems .item4 input {
  outline: none;
  padding: 0 10px;
}

@media (max-width: 576px) {

  .secondarySearchItems .item3,
  .secondarySearchItems .item4 {
    min-width: 100%;
    margin-bottom: 20px !important;
    margin-right: 0px !important;

  }
}



.secondarySearchItems .item3 .select,
.secondarySearchItems .item4 .select {
  padding: 12px 2px;
}

.secondarySearchItems .item5 {
  width: 15%;
  min-width: 165px;
}

@media (max-width: 576px) {

  .secondarySearchItems .item5 {
    min-width: 36%;
    margin-bottom: 20px !important;
    margin-right: 15px !important;
  }

  .secondarySearchItems .item5 p {
    margin-right: 0%;
  }

  .secondarySearchItems .item6 {
    min-width: 59%;
    margin-bottom: 20px !important;
    margin-right: 0px !important;
  }

  .secondarySearchItems .item6 p {
    margin-right: 0 !important;
  }

  .secondarySearchItems .item3 .select,
  .secondarySearchItems .item4 .select {
    padding-left: 20px;
  }
}

.item7 {
  width: 40%;
  background-color: #fff;
  display: flex;
  align-items: center;
  white-space: nowrap;
  min-width: 215px;
  margin-bottom: 30px;
  margin-right: 13px;
  height: 50px;
  box-shadow: 0px 0px 3px #888888;
}

@media (max-width: 576px) {
  .item7 {
    width: 100%;
    margin-right: 0 !important;
  }
}

.item7 input {
  width: 100%;
  border: none;
  outline: none;
  padding: 12px 19px;
  color: var(--cmn-text-color-ash);
  font-family: var(--AppFont);
}

.item7 input,
.item7 input::placeholder {
  color: var(--cmn-text-color-ash);
  font-family: var(--AppFont);
  font-weight: bold;
  font-size: 0.8rem;
}

.secondarySearchItems hr {
  background-color: var(--cmn-text-color-ash);
  opacity: 1;
  height: 2px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  padding: 17px 50px;
  background-color: var(--cmn-text-color-dark);
  color: #FFFFFF;
  font-family: var(--AppFont);
  font-weight: 500;
  margin-top: 10px;
}

.button:disabled {
  background-color: #115151 !important;
  cursor: not-allowed;
}

.warningText {
  font-family: var(--AppFont);
  color: var(--cmn-text-color-ash);
  text-align: center;
  text-decoration: none;
  margin-top: 20px;
  margin-bottom: 0 !important;
  border-bottom: 1px solid var(--cmn-text-color-ash);
  width: min-content;
  white-space: nowrap;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.title {
  font-family: 'Noto Sans JP', sans-serif;
  color: var(--cmn-text-color-dark);
  padding-left: 8px;
  border-left: 5px solid var(--cmn-text-color-dark);
  font-weight: bold;
}

@media (max-width: 576px) {
  .title {
    margin: 35px 0px;
    font-size: 1.2rem;
  }
}

@media (max-width: 576px) {

  .secondarySearchItems .item1,
  .secondarySearchItems .item2,
  .secondarySearchItems .item3,
  .secondarySearchItems .item4,
  .secondarySearchItems .item5,
  .secondarySearchItems .item6,
  .secondarySearchItems .item7 {
    height: 46px;
  }

  .searchBOX .mainFilterOptions .type2 .gender div {
    padding: 6px 9px
  }

  .button {
    width: 100%;
  }
}


.cross {
  position: absolute;
  top: 3%;
  right: 1%;
  cursor: pointer;
}

@media (max-width: 576px) {
  .history .cross {
    position: absolute;
    top: 3%;
    right: 1%;
    cursor: pointer;
    width: 30px !important;
    height: 30px !important;
  }
}

.cross .crossIcon {
  font-size: 1rem !important;
}

@media (max-width: 576px) {

  .secondarySearchItems .item2,
  .secondarySearchItems .item5,
  .secondarySearchItems .item6,
  .secondarySearchItems .item3,
  .secondarySearchItems .item4 {
    padding-left: 12px;
  }
}

.searchHistoryItem {
  font-family: 'Noto Sans JP', sans-serif;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


.secondaryLabelLocation,
.secondaryLabelbreedView {
  width: 230px;
}

.secondaryLabelColor {
  width: 150px;
}

@media (max-width: 576px) {
  .secondaryLabelColor {
    width: 100px;
  }

  .secondaryLabelLocation,
  .secondaryLabelbreedView {
    width: 250px;
  }
}

@media (max-width: 400px) {


  .secondaryLabelLocation,
  .secondaryLabelbreedView {
    width: 230px;
  }
}