.PuppyShortCard {
  display: flex;
  cursor: pointer;
  min-width: 40% !important;
}

.PuppyShortCard:hover {
  opacity: 1;
}

.imgContainer {
  width: 40%;
}

@media (max-width: 576px) {
  .imgContainer {
    width: 100%;
  }
}

.detailsContainer {
  width: 55%;
  margin-left: 5%;
}

.gender {
  background-color: var(--cmn-rose);
  padding: 2px 8px;
  font-family: "Noto Sans JP", sans-serif;
  width: max-content;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 500;
}

.type {
  font-family: 'Noto Sans JP', sans-serif;
  color: var(--cmn-text-color-ash);
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 0 !important;
  margin-top: 15px;
}

.date {
  font-family: 'Noto Sans JP', sans-serif;
  color: var(--cmn-text-color-ash);
  font-weight: 400;
  font-size: 0.8rem;
  margin-bottom: 10px;
}

.price {
  font-family: 'Noto Sans JP', sans-serif;
  color: var(--cmn-text-color-ash);
  font-weight: 600;
  font-size: 0.9rem;
}