.company {
  background-color: var(--cmn-gray-shade-100);
  width: 100%;
  height: auto;
  padding: 4% 0 5% 0;
}

.TermsAndCondition {
  background-color: var(--cmn-gray-shade-100);
  width: 100%;
  height: auto;
  padding: 4% 0 5% 0;
}

.title {
  font-family: 'Noto Sans JP', sans-serif;
  color: var(--cmn-text-color-dark);
  padding-left: 8px;
  border-left: 5px solid var(--cmn-text-color-dark);
  font-weight: bold;
}

@media (max-width: 576px) {
  .title {
    margin: 25px 0px;
    font-size: 1.2rem;
  }
}

.key,
.value {
  font-family: 'Noto Sans JP', sans-serif;
  color: var(--cmn-gray-shade);
  font-size: 1rem;
}

.key {
  width: 25%;
  background-color: var(--cmn-slate-green);
  padding: 30px 0 30px 3%;
  border-bottom: 2px solid #fff;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.value {
  width: 75%;
  padding: 30px 0 30px 6%;
  background-color: #fff;
  border-bottom: 2px solid var(--cmn-gray-shade-100);
  font-weight: 500;
}

.termsContainer {
  background-color: #fff;
  padding: 3%;
}

.termsContainer p {
  color: var(--cmn-gray-shade);
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 0.85rem;
  font-weight: 500;
}

@media (max-width: 576px) {
  .company {
    padding: 0% 0 5% 0;
  }

  .TermsAndCondition {
    padding: 0% 0 5% 0;
  }

  .key,
  .value {
    font-size: 0.9rem;
    border: none;
    width: 100%;
    padding: 15px 30px;
  }

  .termsContainer p {
    font-size: 0.7rem;
  }


}