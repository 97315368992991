.showMobileMenu {
  animation: showMobileMenuAnimation 0.5s 1;
}

.hideMobileMenu {
  animation: hideMobileMenuAnimation 0.5s 1;
}

@keyframes showMobileMenuAnimation {
  from {
    right: -280px;
  }

  to {
    right: 0px;
  }
}

@keyframes hideMobileMenuAnimation {
  from {
    right: 0px;
  }

  to {
    right: -280px;
  }
}

.mobileMenu {
  position: fixed;
  top: var(--mobile-header-height);
  right: 0;
  width: 85%;
  background-color: #fff;
  height: calc(100vh - var(--mobile-header-height));
  z-index: 99999999999996;
  overflow-x: scroll;
}

.group {
  display: block;
}

.title {
  background-color: var(--cmn-gray-light);
  width: 100%;
  padding: 10px 15px;
  font-family: var(--AppFont);
  font-weight: 500;
  font-size: 0.9rem;
}

.authConten {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 10px 20px 15px;
}

.loginButton,
.regButton,
.logoutButton {
  font-family: var(--AppFont);
  margin: auto;
  padding: 7px 20px;
  border: 1px solid #8F9DB7;
  border-radius: 5px;
  font-size: 0.9rem;
  text-decoration: none;
  font-weight: 500;
}

.loginButton {
  color: #8F9DB7;
  background-color: #fff;
}

.LinkedItem {
  display: block;
  font-family: var(--AppFont);
  font-weight: 500;
  padding: 9px 0px 9px 30px;
  border-bottom: 1px solid var(--cmn-gray-light);
  font-size: 0.9rem;
  width: 100%;
  text-decoration: none;
  color: var(--bs-body-color);
  background-color: white;
  filter: drop-shadow(2px 0px 1px var(--cmn-gray-light));
  display: flex;
  align-items: center;
}

.LinkedItem span {
  margin-left: auto;
  margin-right: 5%;
  color: #666666;
  transform: scale(1.3);
}

.LinkedItem .img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 5%;
  overflow: hidden;
}

.LinkedItem .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.2);
}


.regButton {
  color: #fff;
  background-color: #8F9DB7;
}

.logoutButton {
  color: #ff0000;
  background-color: #fff;
}

.loggedInItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loggedInItems .loggedInItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--cmn-text-color-dark);
  text-decoration: none;
  border: 2px solid var(--cmn-text-color-dark);
  border-radius: 10px;
  padding: 3px 5px;
  width: 80px;
  margin: 0 4px;
}

.loggedInItem p {
  font-family: var(--AppFont);
  font-size: 0.82rem;
  margin-bottom: 0 !important;
}

.secondMenu {
  position: fixed;
  top: var(--mobile-header-height);
  right: 0;
  width: 280px;
  background-color: #fff;
  height: calc(100vh - var(--mobile-header-height));
  z-index: 99999999999996;
}

.secondMenu .header {
  padding: 8px 10px;
  background-color: var(--cmn-gray-light);
  display: block;
  position: relative;
}

.secondMenu .header p {
  text-align: center;
  margin-bottom: 0 !important;
  font-family: var(--AppFont);
  font-weight: 600;
}

.header span {
  font-variation-settings: 'FILL'0, 'wght'800, 'GRAD'0, 'opsz'48;
  margin-right: auto;
  position: absolute;
  cursor: pointer;
}

.secondDiv {
  overflow-y: scroll;
  height: 93%;
}