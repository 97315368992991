.PopularBreederItem {
  background-color: #fff;
  padding: 2%;
  color: var(--cmn-text-color-ash);
}

.items {
  display: flex;
  justify-content: start;
  margin: 0 3%;
}

@media (max-width: 576px) {
  .PopularBreederItem {
    padding: 1%;
  }
}


.totalH {
  padding: 5px 0;
  font-family: 'Noto Sans JP', sans-serif;
  color: var(--cmn-text-color-ash);
  font-size: 1.3rem;
}

@media (max-width: 1400px) {
  .totalH {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
}

.total {
  padding: 0px 0;
  font-family: 'Noto Sans JP', sans-serif;
  color: var(--cmn-text-color-ash);
  font-size: 1.1rem;
}

.fullColumnWidth .totalH {
  font-size: 1.6rem;
}


.total span {
  font-weight: 600;
}

@media (max-width: 576px) {
  .totalH {
    font-size: 1rem;
    margin-bottom: 0 !important;
  }

  .total {
    font-size: 1rem;
  }
}

.left {
  width: 20%;
  font-family: "Noto Sans JP", sans-serif;
  margin-bottom: 0 !important;
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--cmn-text-color-ash);
}

.right {
  font-family: 'Noto Sans JP', sans-serif;
  margin-bottom: 0 !important;
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--cmn-text-color-ash);
  margin-left: 2%;
  margin-top: 5px;
  text-decoration: underline;
}

@media (max-width: 1400px) {
  .right {
    margin-top: 4px;
  }
}

@media (max-width: 576px) {
  .right {
    margin-top: 0px;
    text-decoration: none;
  }
}

.leftWithColor1 {
  width: 210px;
  font-family: 'Noto Sans JP', sans-serif;
  margin-bottom: 0 !important;
  font-size: 1rem;
  font-weight: 400;
  background-color: var(--cmn-light-green);
  padding: 2px 8px 2px 20px;
  text-align: left;
  margin-bottom: 0 !important;
  color: var(--cmn-text-color-ash);
  display: flex;
  align-items: center;
}

@media (max-width: 1400px) {
  .leftWithColor1 {
    font-size: 0.9rem;
  }
}

.rightWitchColor1 {
  width: auto;
  font-family: 'Noto Sans JP', sans-serif;
  margin-bottom: 0 !important;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 5px 8px;
  margin-left: 2%;
  text-align: start;
  margin-bottom: 0 !important;
  color: var(--cmn-text-color-ash);
}

@media (max-width: 1400px) {
  .rightWitchColor1 {
    font-size: 0.95rem;
  }
}

.leftWithColor2 {
  width: 210px;
  font-family: 'Noto Sans JP', sans-serif;
  margin-bottom: 0 !important;
  font-size: 1rem;
  font-weight: 400;
  background-color: var(--cmn-periwinkle-blue);
  padding: 2px 8px 2px 20px;
  text-align: center;
  margin-bottom: 0 !important;
  color: var(--cmn-text-color-ash);
  display: flex;
  align-items: center;
}

@media (max-width: 1400px) {
  .leftWithColor2 {
    font-size: 0.9rem;
  }
}

.rightWitchColor2 {
  width: auto;
  font-family: 'Noto Sans JP', sans-serif;
  margin-bottom: 0 !important;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 5px 8px;
  margin-left: 2%;
  text-align: start;
  margin-bottom: 0 !important;
  color: var(--cmn-text-color-ash);
}

@media (max-width: 1400px) {
  .rightWitchColor2 {
    font-size: 0.95rem;
  }
}

.introTitle {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 0.85rem;
  font-weight: bold;
  color: var(--cmn-text-color-ash);
}

.introDetails {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 0.85rem;
  font-weight: 500;
  color: var(--cmn-text-color-ash);
}

.more {
  font-family: "Noto Sans JP", sans-serif;
  color: var(--cmn-text-color-ash);
  font-size: 0.85rem;
  font-weight: 500;
  text-align: end;
  position: relative;
  text-decoration: none;
}

.more::before {
  content: "";
  background-image: url(../../../public/assets/img/arrow.png);
  height: 9px;
  width: 25px;
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  right: 80px;
  top: 5px;
}

.seeAllPuppies {
  font-family: "Noto Sans JP", sans-serif;
  color: var(--cmn-text-color-ash);
  font-size: 0.85rem;
  font-weight: 500;
  text-align: end;
  position: relative;
  text-decoration: none;
}

.handledBreeds {
  font-family: 'Noto Sans JP', sans-serif;
  color: var(--cmn-text-color-ash);
  font-size: 1.1rem;
}

@media (max-width: 1400px) {
  .handledBreeds {
    font-size: 1rem;
  }
}

.fullColumnWidth .handledBreeds {
  font-size: 1.2rem;
}

.Rating {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 1.2rem
}

.fullColumnWidth .Rating span {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 1.3rem !important
}

.Rating span {
  /* font-size: 1.5rem; */
  color: var(--cmn-pumpkin-orange);
  font-weight: 600;
}

@media (max-width: 1400px) {
  .Rating span {
    font-size: 1.4rem;
  }
}

@media (max-width: 576px) {
  .Rating {
    margin-bottom: 0 !important;
  }

  .Rating span {
    font-size: 1.2rem !important;
  }

  .Rating span svg {
    width: 20px;
    height: 20px;
  }

  .fullColumnWidth .Rating span {
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 1.2rem !important
  }
}

.bgPink {
  background-color: var(--cmn-peach-puff);
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

@media (max-width: 576px) {
  .bgPink {
    width: 90% !important;
    margin: 0% 5%;
    padding: 5px 0px;
  }
}

.bgPink h6 {
  margin-bottom: 0 !important;
}

@media (max-width: 576px) {
  .bgPink h6 {
    margin-top: 0 !important;
    padding-top: 0px;
    font-size: 0.8rem;
    text-align: center;
    width: 100%;
  }
}

.bgPink a {
  margin-left: auto;
  text-decoration: none;
  font-family: 'Noto Sans JP', sans-serif;
  color: var(--cmn-text-color-ash);
  position: relative;
  font-weight: 500;
}

.PopularBreederItem a::after {
  content: "";
  position: absolute;
  width: 0%;
  left: 0;
  bottom: -2px;
  border-bottom: 1px solid var(--cmn-text-color-ash);
}

.PopularBreederItem a:hover::after {
  width: 100%;
  transition: width 0.2s ease-in;
}

@media (max-width: 1200px) {
  .leftWithColor1 {
    font-size: 0.9rem;
    padding-left: 7px;
    width: 180px;
  }

  .leftWithColor2 {
    font-size: 0.9rem;
    padding-left: 7px;
    width: 180px;
  }

  .rightWitchColor1 {
    font-size: 0.9rem;
  }

  .rightWitchColor2 {
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  .leftWithColor1 {
    font-size: 0.7rem;
    padding-left: 5px;
    width: 90%;
  }

  .rightWitchColor1 {
    font-size: 0.7rem;
    text-align: start;
    padding: 5px 2px;
  }

  .leftWithColor2 {
    font-size: 0.7rem;
    padding-left: 5px;
    width: 90%;
  }

  .rightWitchColor2 {
    font-size: 0.7rem;
    text-align: start;
    padding: 5px 2px;
  }
}



.mobileLINK {
  margin-left: auto;
  text-decoration: none;
  font-family: 'Noto Sans JP', sans-serif;
  color: var(--cmn-text-color-ash);
  position: relative;
  width: 100%;
  display: flex;
  text-align: end;
  font-size: 0.8rem;
  margin-top: 10px;
  justify-content: end;
  align-items: center;
}

.mobileLINK::after {
  content: "";
  position: absolute;
  width: 0%;
  left: 0;
  bottom: -2px;
  border-bottom: 1px solid var(--cmn-text-color-ash);
}

/* @media (max-width: 1300px) {
  .leftWithColor1 {
    font-size: 0.72rem;
    padding: 5px 4px;
  }

  .leftWithColor2 {
    font-size: 0.72rem;
    padding: 5px 4px;
  }

  .rightWitchColor1 {
    font-size: 0.72rem;
  }

  .rightWitchColor2 {
    font-size: 0.72rem;
  }
} */

.w20 {
  width: 20% !important;
}

.w80 {
  width: 80% !important;
}

.PuppyCardContainer {
  width: 270px !important;
  padding: 0 !important;
  transition: opacity 0.3s ease;
  margin: 0 1.5%;

}

@media (max-width: 576px) {
  .PuppyCardContainer {
    width: 33% !important;
    padding: 0 !important;
  }
}


.PuppyCardContainer:hover {
  opacity: 0.6;
}

.ShortPadding {
  padding: 10px 3%;
}