.customAspectAatio251 {
  position: relative;
  width: 100%;
  padding-top: 40%;
  /* 1 divided by 2.5 (0.4) multiplied by 100 to get the percentage */
}

.customAspectAatio251 .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f1f1f1;
  /* Placeholder background color */
}

.bannerContent {
  position: relative;
  cursor: pointer;
}

.bannerContent p {
  position: absolute;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  color: #fff;
  top: 20%;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  text-align: center;
  font-size: 1.2rem;
}

@media (max-width: 576px) {
  .bannerContent p {
    font-size: 0.9rem;
  }
}

.customAspectAatio11 p {
  top: 40%;
}


.customAspectAatio641 {
  position: relative;
  width: 100%;
  padding-top: calc(1 / 6.4 * 100%);
  background-color: #ccc;
}

.customAspectAatio641 p {
  top: 8%;
}

.customAspectAatio641 .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.customAspectAatio321 {
  position: relative;
  width: 100%;
  padding-top: calc(1 / 3.2 * 100%);
  background-color: #ccc;
}

.customAspectAatio321 .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.customAspectAatio321 p {
  top: 30%;
}