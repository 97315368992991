.topContent {
  position: absolute;
  height: 100%;
  width: 100%;
}

@media (max-width: 576px) {
  .topContent {
    position: relative;
    margin-top: -40px !important;
  }
}

.h2 {
  color: var(--cmn-text-color-dark);
  font-weight: bold;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 1.7rem !important;
  margin-bottom: 0 !important;
}

@media (max-width: 576px) {
  .h2 {
    font-size: 1.2rem !important;
    text-align: center;
    margin-top: 30px;
  }
}


.h1 {
  color: var(--cmn-text-color-dark);
  font-weight: 500;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 2.3rem;
  margin-top: 10px !important;
}

@media (max-width: 576px) {
  .h1 {
    white-space: nowrap;
    font-size: 1.5rem !important;
  }
}

.lookfor {
  font-size: 2.5rem !important;
  color: var(--cmn-slate-green);
  font-weight: bold !important;
}

@media (max-width: 576px) {
  .lookfor {
    font-size: 1.6rem !important;
  }
}



@media (max-width: 1200px) {
  .h1 {
    font-size: 2.1rem;
    line-height: 3rem !important;
  }
}

@media (max-width: 576px) {
  .h1 {
    font-size: 1.4rem;
    line-height: 1.2rem;
    text-align: center;
    margin-bottom: 5px;
  }
}


.cap {
  background-image: url("../../../public/assets/img/cap.png");
  height: 150px;
  width: 150px;
  margin: 10px 0;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 576px) {
  .cap {
    height: 100px;
    width: 100px;
    margin: 10px 0px;
  }
}

.cap p {
  text-align: center;
  font-size: 1.1rem;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  margin-bottom: 0 !important;
}

@media (max-width: 1200px) {
  .cap p {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .cap p {
    font-size: 0.85rem;
  }
}


.cap p span {
  color: var(--cmn-yellow-shade-100);
  font-size: 1.5rem;
}

@media (max-width: 1200px) {
  .cap p span {
    font-size: 1.4rem;
  }
}

@media (max-width: 576px) {
  .cap p span {
    font-size: 0.95rem;
  }
}

.cap:first-child p span:last-child {
  font-size: 1.3rem;
}

@media (max-width: 1200px) {
  .cap:first-child p span:last-child {
    font-size: 1.2rem;
  }
}

@media (max-width: 576px) {
  .cap:first-child p span:last-child {
    font-size: 0.85rem;
  }
}

.cap:nth-child(2) p:last-child {
  font-size: 1.4rem;
}

@media (max-width: 1200px) {
  .cap:nth-child(2) p:last-child {
    font-size: 1.3rem;
  }
}

@media (max-width: 576px) {
  .cap:nth-child(2) p:last-child {
    font-size: 1.05rem;
  }
}

.cap:nth-child(3) p:first-child span {
  font-size: 1.2rem;
  color: var(--cmn-text-color-dark);
}

@media (max-width: 1200px) {
  .cap:nth-child(3) p:first-child span {
    font-size: 1.1rem;
  }
}

@media (max-width: 576px) {
  .cap:nth-child(3) p:first-child span {
    font-size: 0.85rem;
  }
}

.CountContainer {
  position: absolute;
  right: 0;
  top: -150px;
  padding: 10px 10px 10px 12px;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 99999;
  animation: slideIn 0.5s 1;
}

@keyframes slideIn {
  from {
    right: -100px;
  }

  to {
    right: 0px;
  }
}

.mobileRow {
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0 !important;
}