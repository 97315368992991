.localtionModal {
  z-index: 99999999999 !important;
}

.modalHeader {
  border-bottom: none !important;
}

.modalHeader p {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 0 !important;
}

.modalbody {
  padding: 0 5% !important;
  border-bottom: none;
  position: relative !important;
}

.modalbody label {
  display: flex;
  align-items: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 500;
  margin-left: 10px;
  color: var(--cmn-text-color-dark);
}

.modalbody [type="checkbox"] {
  width: 17px;
  height: 17px;
  margin-right: 5px;
}



.borderTOP {
  border-top: 1px solid #6666669f;
}

.borderBottom {
  border-bottom: 1px solid #6666669f;
}

.ashBG {
  background-color: var(--cmn-gray-light);
}

.subsubcheckBox {
  display: flex;
  white-space: nowrap;
  flex-wrap: wrap;
}

.subsubcheckBox label {
  margin: 5px;
  min-width: 125px;
  margin-left: 15px;
}

.modalbody label:nth-child(4n) {
  width: auto;
  min-width: 70px;
}



.button,
.button:hover {
  background-color: #fff !important;
  color: #000 !important;
  font-family: 'Noto Sans JP', sans-serif !important;
  border: 2px solid #000 !important;
  margin: 0 20px !important;
  width: 200px !important;
  transition: background-color 0.2s ease !important;
}

.button:hover {
  background-color: #c1c1c166 !important;
}

.footer {
  padding: 5% 0% !important;
  border-top: none !important;
}


@media (max-width: 576px) {

  .localtionModal {
    overflow: hidden !important;
  }

  .modalbody {
    overflow: scroll;
    padding: 0 3%;
  }




  .mobileLeft {
    width: 40% !important;
    display: flex;
    padding-left: 5px !important;
  }

  .mobileRight {
    width: 60% !important;
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button,
  .button:hover {
    width: auto;
    margin: 0 10px !important;
    padding: 9px 0px !important;
    border-radius: 3px !important;
    color: var(--cmn-text-color-dark) !important;
    border: 1px solid var(--cmn-text-color-dark) !important;
    font-weight: bold !important;
    border-radius: 5px !important;
  }

  .modalbody label {
    font-size: 1rem;
    white-space: nowrap;
    margin-left: 25px;

  }


  .modalbody [type="checkbox"] {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    appearance: none;
    border: 1px solid var(--cmn-text-color-dark);
  }

  .modalbody [type="checkbox"]:checked {
    background-image: url(../../../../public/assets/img/yellowChecked.svg);
    border: none;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    transform: scale(1.15);
  }

  .modalbody [type="checkbox"]:checked::after {
    content: "";
  }


  .subsubcheckBox label {
    width: 40%;
    margin: 5px 0 5px 0;
    min-width: 40%;
    font-size: 1rem;
  }

  .mainCheckedMark {
    margin-left: 2px !important;
  }

  .subsubcheckBox label:nth-child(2n+1) {
    margin-right: 5%;
    margin-left: 7%;
  }

  .subsubcheckBox label:nth-child(2n) {
    margin-left: 5% !important;
  }

  .modalbody label:nth-child(2n) {
    min-width: 70px;
  }

  .footer {
    padding: 0% 0% !important;
    background-color: var(--cmn-footer-color-dark153);
  }

  .button:nth-child(2) {
    background-color: #F6D25B !important;
    width: 58% !important;
  }

  .button:nth-child(1) {
    background-color: #E9E8E8 !important;
    width: 25.6% !important
  }


}