.breederCount {
  font-family: 'Noto Sans JP', sans-serif;
  color: var(--cmn-gray-shade);
  font-size: 0.9rem;
  margin-bottom: 2px;
}

.ratingCount {
  color: var(--cmn-pumpkin-orange);
  margin-bottom: 5px !important;
}

.emptyStar {
  color: #D3D3D3;
}

.breederDetails {
  font-family: 'Noto Sans JP', sans-serif;
  color: var(--cmn-text-color-ash);
  font-size: 0.8rem;
  margin-bottom: 2px;
  font-weight: 500;
}

.breederCountTxt {
  font-weight: bold;
  color: var(--cmn-text-color-ash);
}

.address {
  font-family: 'Noto Sans JP', sans-serif;
  color: var(--cmn-gray-shade);
  font-size: 0.8rem;
  margin-top: 5px;
}

@media (max-width: 1200px) {
  .address {
    font-size: 0.7rem;
  }
}

@media (max-width: 1400px) {
  .breederCount {
    font-size: 0.8rem;
  }
}