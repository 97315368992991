.item {
  width: 18.6% !important;
  padding: 0 !important;
  cursor: pointer;
  position: relative;
  text-decoration: none;
}


.fulWidth {
  width: 15.6% !important
}

@media (max-width: 1400px) {
  .item {
    width: 18.4% !important;
  }

  .fulWidth {
    width: 18.6% !important
  }

}

@media (max-width: 576px) {
  .item {
    width: 47% !important;
    margin: 0;
    margin-bottom: 20px;
  }
}

.imageContainer {
  overflow: hidden;
  position: relative;
}

.image {
  transition: transform 0.5s ease;
}


.breedName {
  font-family: 'Noto Sans JP', sans-serif;
  color: var(--cmn-text-color-ash);
  text-align: center;
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 0 !important;
}

.badge {
  background-image: url(../../../public/assets/img/regularBadge.png);
  height: 32px;
  width: 27px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  font-family: 'Noto Sans JP', sans-serif;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  position: absolute;
  top: -3%;
  left: 5%;
}

.badgeFirst {
  background-image: url(../../../public/assets/img/1stBadge.png);
}

.badgeSecond {
  background-image: url(../../../public/assets/img/2ndBadge.png);
}

.badgeThird {
  background-image: url(../../../public/assets/img/3rdBadge.png);
}