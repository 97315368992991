.filterBar {
  width: 100%;
  height: 210px;
  background-color: var(--cmn-pastel-yellow);
  display: flex;
  margin-top: -0.7%;
  position: relative;
}

.refComponent {
  position: absolute;
  top: -100px;
}

@media (max-width: 576px) {
  .filterBar {
    height: auto;
    padding: 12px 0;
    margin-top: 10px;
  }
}

.button {
  font-family: 'Noto Sans JP', sans-serif !important;
  color: var(--cmn-gray-shade) !important;
  font-size: 1rem !important;
  margin: 0 3px;
  margin-bottom: 22px;
  font-weight: 500 !important;
  border: 1px solid var(--cmn-gray-light) !important;
  padding: 10px 26px;
  background-color: #fff;
}

@media (max-width: 1400px) {
  .button {
    font-size: 0.88rem !important;
    padding: 8px 20px;
  }
}

.button:first-child {
  margin-left: 0 !important;
}

@media (max-width: 576px) {
  .button {
    font-size: 0.9rem !important;
    font-weight: 500 !important;
    padding: 8px 0px;
    margin: 0 2px;
    margin-bottom: 12px;
  }

  .button:nth-child(1) {
    width: 29%;
  }

  .button:nth-child(2) {
    width: 29%;
  }

  .button:nth-child(3) {
    width: 40%;
  }
}


.active {
  background-color: var(--cmn-text-color-dark) !important;
  --bs-btn-border-color: var(--cmn-text-color-dark) !important;
  color: #fff !important;
  z-index: 99;
  position: relative;
  border: none !important;
}

.active::after {
  content: "";
  border: none;
  position: absolute;
  height: 28px;
  width: 28px;
  background-image: url(../../../public/assets/img/triangleBottom.png);
  background-repeat: no-repeat;
  background-size: contain;
  top: 36px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1400px) {
  .active::after {
    height: 25px;
    width: 25px;
  }
}

@media (max-width: 576px) {
  .active::after {
    height: 18px;
    width: 18px;
    top: 36px;
  }
}


.button:first-of-type::after {
  border: none;
}

.button:last-of-type::after {
  border: none;
}

.button:hover {
  z-index: 99;
  --bs-btn-hover-bg: var(--cmn-gray-shade-100);
  --bs-btn-hover-border-color: var(--cmn-gray-shade-100);
}



.searchButton {
  background-color: var(--cmn-text-color-dark) !important;
  font-family: 'Noto Sans JP', sans-serif;
  border-radius: 0 !important;
  color: white;
  border: none;
  height: 100%;
  width: 100%;
}

.searchButton:disabled {
  background-color: #115151 !important;
  cursor: not-allowed;
}

@media (max-width: 576px) {
  .searchButton {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
    padding: 15px 0px !important;
    height: 55px !important;
  }
}

.select1 {
  position: relative;

  &::after {
    content: "";
    width: 30px;
    height: 30px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 10px;
    background-image: url(../../../public/assets/img/pow.png);
  }
}

.boxItem {
  width: 100%;
  height: 100%;
  border: 1px solid #2b2b2b;
  border-left: 0;
  border-right: 0;
  padding-left: 45px;
  padding-right: 5px;
  position: relative;
  background-color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 50px;
}

.breeder {
  &::after {
    content: "";
    width: 30px;
    height: 30px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 10px;
    background-image: url(../../../public/assets/img/user.png);
  }
}

.breed {
  &::after {
    content: "";
    width: 30px;
    height: 30px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 10px;
    background-image: url(../../../public/assets/img/pow.png);
  }
}

.location {
  &::after {
    content: "";
    width: 30px;
    height: 30px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 10px;
    background-image: url(../../../public/assets/img/location.png);
  }
}

.RightBorder {
  border-right: 1px solid #2b2b2b;
}

.LeftBorder {
  border-left: 1px solid #2b2b2b;
}

.boxItem p {
  color: hsl(0, 0%, 20%);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0 !important;
}

.select2 {
  position: relative;

  &::after {
    content: "";
    width: 30px;
    height: 30px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 10px;
    background-image: url(../../../public/assets/img/location.png);
  }
}

.select3 {
  position: relative;

  &::after {
    content: "";
    width: 30px;
    height: 30px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 10px;
    background-image: url(../../../public/assets/img/user.png);
  }
}


@media (max-width: 576px) {

  .locationBox {
    height: 50px;
    border: 1px solid;
    border-radius: 10px;
  }

}

option:first-child {
  color: red;
  /* Change the color to red as an example */
}

@media (max-width: 576px) {
  .mobileSelect select {
    width: 100% !important;
    height: 50px;
    margin: 7px 0px;
    border: 1px solid #515151 !important;
    border-radius: 7px !important;
  }
}

.buttonGroup {
  width: 44%;
}

@media (max-width: 1400px) {
  .buttonGroup {
    width: 42.5%;
  }
}

@media (max-width: 1200px) {
  .buttonGroup {
    width: 60%;
  }
}

@media (max-width: 1000px) {
  .buttonGroup {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .buttonGroup {
    width: 100%;
    justify-content: center;
  }

  .locationBoxRightBorder {
    border-right: 1px solid #2b2b2b;
  }
}