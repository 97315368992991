.PopularDogBreed {
  font-family: 'Noto Sans JP', sans-serif;
  color: var(--cmn-text-color-ash);
  font-weight: bold;
  font-size: 1rem;
}

.link {
  font-family: 'Noto Sans JP', sans-serif;
  color: var(--cmn-text-color-ash);
  font-weight: 500 !important;
  text-decoration: none;
  font-size: 0.8rem;
  position: relative;
  font-weight: bold;
  letter-spacing: 0.03rem;
  cursor: pointer;
}

.link::after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: -1px;
  width: 0;
  height: 0px;
  background-color: black;
  transition: width 0.3s ease-in-out;
}

.link:hover::after {
  width: 100%;
  height: 1px;
}

.viewAll {
  font-family: 'Noto Sans JP', sans-serif;
  color: var(--cmn-text-color-ash);
  font-size: 1rem;
  text-decoration: none;
  width: 100%;
  padding: 10px 20px;
  border: 2px solid var(--cmn-text-color-ash);
  display: inline-block;
  position: relative;
  text-decoration: none;
}

.viewAll::after {
  position: absolute;
  content: "";
  background-image: url(../../../../public/assets/img/arrowLeft.png);
  width: 13px;
  height: 13px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  left: 70%;
  text-decoration: none;
  transition: left 0.2s ease-in;
}

.viewAll:hover::after {
  left: 75%;
}

@media (max-width: 1400px) {
  .viewAll::after {
    left: 80%;
  }

  .viewAll:hover::after {
    left: 85%;
  }
}


@media (max-width: 1200px) {
  .viewAll {
    font-size: 0.9rem;
    padding-left: 8px;
  }

  .viewAll::after {
    left: 80%;

  }
}