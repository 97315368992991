.heading {
  font-family: var(--AppFont);
  font-weight: bold;
  padding-left: 7px;
  color: var(--cmn-text-color-dark);
  border-left: 4px solid var(--cmn-text-color-dark);
}

@media (max-width: 576px) {
  .heading {
    padding-left: 5px;
    border-left: 5px solid var(--cmn-text-color-dark);
    font-size: calc(1.1rem + .3vw);
  }
}

.seeAllLink {
  font-family: var(--AppFont);
  text-align: end;
  margin-left: auto;
  display: flex;
  align-items: end;
  text-decoration: none;
  color: var(--cmn-text-color-ash);
  font-weight: 500;
  margin-bottom: 16px;
  margin-top: auto;
}

@media (max-width: 576px) {
  .seeAllLink {
    margin-right: -2px;
    margin-bottom: 5px;
  }
}


.animalItemRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 0 15px;
}

@media (max-width: 1400px) {
  .animalItemRow {
    gap: 0 10px;
  }
}

@media (max-width: 576px) {
  .animalItemRow {
    display: inline-block;
    overflow-x: scroll;
    white-space: nowrap;
  }
}

.itemCol {
  display: flex;
  gap: 0 3%;
  flex-wrap: wrap;
}

.itemCol .width50 {
  width: 48%;
}

.animalItemCard {
  width: 19%;
  min-width: 230px;
}

@media (max-width: 1400px) {
  .animalItemCard {
    width: 19%;
    min-width: 200px;
  }
}


@media (max-width: 1200px) {
  .animalItemCard {
    width: 33.33%;
    min-width: 207px;
    padding: 0 7px;
  }
}

@media (max-width: 1000px) {
  .animalItemCard {
    width: 50%;
    min-width: 210px;
    padding: 0 7px;
  }
}

@media (max-width: 576px) {
  .animalItemCard {
    width: 100%;
    min-width: 100%;
    padding: 0;
  }

  .animalItemRow .animalItemCard {
    margin-top: 15px;
  }
}

.animalItemCardGrid {
  width: 100% !important;
}

.gridListmobileOptions {
  background-color: var(--cmn-gray-shade);
  display: flex;
  flex-direction: row;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;
}

.gridListmobileOptions p {
  margin-bottom: 0 !important;
  color: #fff;
  font-family: var(--AppFont);
  font-size: 0.8rem;
}

.gridListmobileOptions p:nth-child(2) {
  margin-left: auto;
}

.gridListmobileOptions div {
  display: flex;
  margin-left: 2%;
}

.gridListmobileOptions div .icon {
  color: #fff;
  font-size: 0.9rem;
  margin-right: 5px;
  cursor: pointer;
}


.loadMoreReviewButton {
  padding: 13px 25px;
  color: var(--cmn-text-color-ash);
  border: 0;
  outline: none;
  background-color: var(--cmn-gray-light);
  font-weight: 500;
  margin-top: 2%;
}

@media (max-width: 576px) {
  .loadMoreReviewButton {
    font-size: 0.9rem;
  }
}

.buttonYellow {
  background-color: var(--cmn-pastel-yellow);
  padding: 13px 85px;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease;
}

@media (max-width: 576px) {
  .buttonYellow {
    width: 100%;
    font-size: 0.9rem;
    padding: 12px;
  }
}

.buttonYellow:hover {
  border: 2px solid var(--cmn-pastel-yellow);
  background-color: #fff;
  padding: 11px 85px;
}

.totalSummeryText {
  font-family: var(--AppFont);
  text-align: start;
  align-items: end;
  color: var(--cmn-text-color-dark);
  font-weight: 500;
  margin-bottom: 0 !important;
}

.countText {
  font-family: var(--AppFont);
  font-weight: 500;
  margin-bottom: 17px;
}