.SecondHeader {
  padding-top: 16px;
  padding-bottom: 9px;
  width: 100%;
  background-color: #fff;
  /* filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.3)); */
  animation: increaseOpacity 0.3s 1;
  border-bottom: 2px solid var(--cmn-gray-light);
}

@keyframes increaseOpacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media (max-width: 576px) {
  .SecondHeader {
    animation: increaseOpacity 1s 1;
    padding: 0px 15px;
    margin-top: var(--mobile-header-height);
    margin-bottom: calc(-1 * var(--mobile-header-height));
    position: fixed;
    top: 0px;
    z-index: 99999999999999;
    padding-right: 0 !important;
  }
}