.arrayModal {
  z-index: 99999999999 !important;
}

.modalHeader {
  border-bottom: none !important;
}

.modalHeader p {
  font-family: 'Noto Sans JP', sans-serif !important;
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 0 !important;
}


.modalbody {
  padding: 0 5% !important;
  border-bottom: none !important;
  height: 70vh !important;
  overflow-y: scroll !important;
}

.modalbody label {
  display: flex;
  overflow: wrap;
  align-items: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 500;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.modalbody [type="checkbox"] {
  width: 17px;
  height: 17px;
  margin-right: 5px;
}

.col {
  display: flex;
  flex-wrap: wrap;
}


.borderTOP {
  border-top: 1px solid #6666669f;
  padding: 10px 0;
}

.button,
.button:hover {
  background-color: #fff !important;
  color: #000 !important;
  font-family: 'Noto Sans JP', sans-serif !important;
  border: 2px solid #000 !important;
  margin: 0 20px !important;
  width: 200px !important;
  transition: background-color 0.2s ease !important;
}

.button:hover {
  background-color: #c1c1c166 !important;
}

.footer {
  padding: 30px 0 !important;
  background-color: var(--cmn-footer-color-dark153);
}

@media (max-width: 576px) {

  .arrayModal {
    overflow: hidden !important;
  }

  .modalbody {
    height: 77vh !important;
    overflow: scroll !important;
  }

  .button,
  .button:hover {
    width: auto !important;
    margin: 0 10px !important;
    padding: 9px 0px !important;
    border-radius: 3px !important;
    color: var(--cmn-text-color-dark) !important;
    border: 2px solid var(--cmn-text-color-dark) !important;
    font-weight: bold !important;
    border-radius: 5px !important;
  }


  .modalbody label {
    font-size: 0.9rem;
    margin-left: 15px;
  }



  .modalbody [type="checkbox"] {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    appearance: none;
    border: 1px solid var(--cmn-text-color-dark);
  }

  .modalbody [type="checkbox"]:checked {
    background-image: url(../../../public/assets/img/yellowChecked.svg);
    border: none;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    transform: scale(1.15);
  }

  .button,
  .button:hover {
    width: auto !important;
    margin: 0 10px !important;
    padding: 9px 10px !important;
    border-radius: 3px !important;
    color: var(--cmn-text-color-dark) !important;
    border: 1px solid var(--cmn-text-color-dark) !important;
    font-weight: bold !important;
    border-radius: 5px !important;
  }

  .button:nth-child(2) {
    background-color: #F6D25B !important;
    width: 58% !important;
  }

  .button:nth-child(1) {
    background-color: #E9E8E8;
    width: 25.6%
  }

  .footer {
    padding: 0 !important;
    background-color: var(--cmn-footer-color-dark153);
  }
}


.searchHistory .searchmodalHeader p {
  text-align: start;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  width: 100%;
  margin-bottom: 0 !important;
}

.searchHistoryItem {
  width: 100%;
  margin: 15px 5px;
  padding: 5px 10px;
  border: 2px solid;
  cursor: pointer;
  font-family: 'Noto Sans JP', sans-serif;
}