.footer {
  position: relative;
}

.footer .footerContainer {
  background-color: var(--cmn-slate-green);
  position: relative;
  padding: calc(20px + 2vw) 0 30px 0;
  z-index: 20;
}

@media (max-width: 576px) {
  .footer .footerContainer {
    background-color: var(--cmn-slate-green);
    position: relative;
    padding: calc(20px + 2vw) 0 20px 0;
    z-index: 20;
  }
}

.col {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.boldLink {
  font-family: 'Noto Sans JP', sans-serif;
  text-decoration: none;
  color: var(--cmn-text-color-dark);
  font-size: 1rem;
  font-weight: bold;
  margin: 10px 20px;
}

.link {
  font-family: 'Noto Sans JP', sans-serif;
  text-decoration: none;
  color: var(--cmn-text-color-dark);
  font-size: 1rem;
  font-weight: 500;
  margin: 10px 20px;
}

@media (max-width: 576px) {
  .link {
    font-size: 0.75rem;
    margin: 6px 0px;
  }
}

.goUpBar {
  position: absolute;
  top: -50px;
  right: 10%;
  z-index: 9999;
  width: 50px;
  height: 50px;
}

@media (max-width: 576px) {
  .goUpBar {
    top: -45px;
    width: 45px;
    height: auto
  }
}

.copyRight {
  font-family: 'Noto Sans JP', sans-serif;
  color: var(--cmn-green-shade);
  margin-top: 2%;
  font-weight: 500;
}

@media (max-width: 576px) {
  .copyRight {
    margin-top: 5%;
    font-size: 0.7rem;
  }
}