@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&display=swap');



:root {
  --AppFont: 'Noto Sans JP', sans-serif;
  --mobile-header-height: 60px;
}

.notoFont {
  font-family: var(--AppFont);
}

.localtionModal .btn-close {
  position: absolute;
  right: 6%;
  opacity: 1;
}

.material-symbols-outlined {
  font-variation-settings:
    'FILL'0,
    'wght'400,
    'GRAD'0,
    'opsz'48
}

.main {}

.mobileModal {
  height: 100%;
}

.mobileModal .modal-dialog {
  margin: 0 !important;
  height: 100%
}

.mobileModal .modal-dialog .modal-content {
  border-radius: 0 !important;
  height: 100%;
  position: relative;
}

.mobileModal .modal-dialog .modal-content .modal-header {
  height: 60px !important;
  width: 100%;
  top: 0;
}

.mobileModal .modal-dialog .modal-content form {
  height: calc(100% - 60px) !important;
  padding-bottom: 80px;
}


.mobileModal .modal-dialog .modal-content form .modal-body {
  height: 100%;
  padding-bottom: 5%;
  overflow-x: hidden;

}

.mobileModal .modal-dialog .modal-content form .modal-footer {
  height: 60px;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-radius: 0;
}


@media (max-width: 576px) {
  .mps-0 {
    padding-left: 6px !important;
  }

  .container {
    padding: 0 4%;
  }
}