.SideBar {
  width: 95%;
  margin-left: 5%;
}

@media (max-width: 576px) {
  .SideBar {
    width: 100%;
    margin-left: 0%;
  }
}

.heading {
  font-family: 'Noto Sans JP', sans-serif;
  color: var(--cmn-text-color-ash);
  font-weight: 500;
  margin-top: 13px;
}

@media (max-width: 576px) {
  .heading {
    font-size: 1.2rem;
    margin-bottom: 0 !important;
    padding-left: 8px !important;
    border-left: 5px solid var(--cmn-text-color-dark);
    margin-bottom: 15px !important;
    color: var(--cmn-text-color-dark);
    margin-left: 3.5%;
    margin-top: 0px;
  }
}

.showAllRatinLink {
  font-family: 'Noto Sans JP', sans-serif;
  color: var(--cmn-text-color-ash);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
}

@media (max-width: 576px) {
  .showAllRatinLink {
    font-size: 0.8rem;
    background-color: var(--cmn-gray-light);
    width: 110%;
    padding: 3px 0;
    border-radius: 5px;
    margin-left: -5%;
  }
}

.mobilePadding {
  padding: 5% 7%;
}