:root {
  --cmn-bg: #dbdbdb;
  --cmn-ash-lite: #cfcfcf;
  --cmn-yollow-deep: #ff9428;
  --cmn-yollow: #f8d07d;
  --cmn-yollow-hover: #bda366;
  --cmn-yollow-lite: #fff1d6;
  --cmn-green-deep: #389038;
  --cmn-green: #c4ffcc;
  --cmn-blue: #c2ceff;
  --cmn-blue-deep: #4cb2ff;
  --cmn-pink: #ee78b2;
  --cmn-red: #ff4848;
  --cmn-text-color-dark: #2b2b2b;
  --cmn-footer-color-dark153: #2B2B2B99;
  --cmn-text-color-ash: #4f4f4f;

  /* Colors from hosino san's cmyk template */
  --cmn-pastel-yellow: #FED858;
  --cmn-slate-green: #ADD997;
  --cmn-green-shade: #0A6635;
  --cmn-teal-green: #006633;
  --cmn-gray-light: #e8e8e8;
  --cmn-gray-shade: #4f4f4f;
  --cmn-gray-shade-100: #efefef;
  --cmn-yellow-shade-100: #E5BF0C;
  --cmn-gainsboro-gray: #D3D3D3;
  --cmn-sky-blue: #4CA3FF;
  --cmn-golden-rod: #FAAF00;
  --cmn-red: #FF4848;
  --cmn-crimson: #FF5858;
  --cmn-pumpkin-orange: #FFBB15;
  --cmn-orange-shed: #FCA265;
  --cmn-mango-orange: #FF8528;
  --cmn-dusty-rose: #ED8099;
  --cmn-rose: #ED8099;
  --cmn-green-deep: #388038;
  --cmn-chartreuse: #ACDC00;
  --cmn-silver: #A4A4A4;
  --cmn-midnight-blue: #101C46;
  --cmn-peach-puff: #FFE2C7;
  --cmn-light-green: #BFFFB8;
  --cmn-periwinkle-blue: #BCE7FF;
  --cmn-orange-brown: #EB994F;

}

.main {
  overflow-x: hidden;
}

.react-date-picker__wrapper,
.react-time-picker__wrapper {
  border: unset !important;
}

.react-date-picker.react-date-picker--open.react-date-picker--enabled.form-control {
  z-index: 5 !important;
}

.react-date-picker__calendar {
  z-index: 5 !important;
}

.cmn-text-color {
  color: var(--cmn-text-color-dark);
}

.cmn-text-color-ash {
  color: var(--cmn-text-color-ash);
}

.cmn-pastel-yellow-bg {
  background-color: var(--cmn-pastel-yellow);
}

.cmn-yellow-border {
  border-color: var(--cmn-pastel-yellow) !important;
}

.cmn-yollow-bg {
  background-color: var(--cmn-yollow);
}

.cmn-ash-lite-bg {
  background-color: var(--cmn-ash-lite);
}

.cmn-yollow-lite-bg {
  background-color: var(--cmn-yollow-lite);
}

.cmn-blue-bg {
  background-color: #9eacc6;
}

.cmn-pink-bg {
  background-color: var(--cmn-pink);
}

.cmn-blue-deep-bg {
  background-color: var(--cmn-blue-deep);
}

.cmn-green-bg {
  background-color: var(--cmn-green);
}

.cmn-blue-bg {
  background-color: var(--cmn-blue);
}

.cmn-bg {
  background-color: var(--cmn-bg);
}

.w-content-fit {
  width: fit-content;
}

.w-30 {
  width: 30% !important;
}

.fs-7 {
  font-size: 0.8rem !important;
}

.cmn-yollow-btn {
  background-color: var(--cmn-pastel-yellow) !important;
  --bs-btn-border-color: var(--cmn-pastel-yellow) !important;
  font-family: "Noto Sans JP", sans-serif !important;
  color: var(--cmn-text-color-ash) !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  padding: 10px 50px !important;
  border-radius: 0 !important;
}

.cmn-yollow-btn:hover {
  background-color: var(--cmn-yollow-hover) !important;
  --bs-btn-hover-bg: var(--cmn-yollow-hover) !important;
  --bs-btn-hover-border-color: var(--cmn-yollow-hover) !important;
  color: var(--cmn-text-color-ash) !important;
}

.modal {
  background-color: #0000005f;
}

@media (max-width: 576px) {
  .modal {
    background-color: #0000005f;
  }

  .star-ratings {
    transform: translateY(-5px);
  }
}

.parentTag {
  font-family: 'Noto Sans JP', sans-serif;
  width: fit-content;
  padding: 3px 8px;
  margin: 10px 0px;
  color: #fff;
  font-size: 0.8rem;
  background-color: var(--cmn-chartreuse);
  height: fit-content;
}

@media (max-width: 576px) {
  .parentTag {
    align-self: center;
  }
}

ul{
  padding-left: 1.2rem !important;
}