.bgBlue {
  background-color: var(--cmn-slate-green) !important;
}

.bgTransparent {
  background-color: #ffffff00 !important;
  margin: 20px 0px !important;
}

.content a {
  font-family: var(--AppFont);
  text-decoration: none;
  color: var(--cmn-green-shade);
  margin-right: 1%;
  white-space: nowrap;
  position: relative;
  font-weight: 500;
  font-size: 0.9rem;
}

@media (max-width: 576px) {
  .content a {
    font-size: 0.75rem;
  }
}


.content a::after {
  content: ">";
  font-family: var(--AppFont);
  margin-left: 2px;
  font-weight: 500;
}

.content p {
  font-family: var(--AppFont);
  color: var(--cmn-green-shade);
  margin-right: 1%;
  white-space: nowrap;
  position: relative;
  margin-bottom: 0 !important;
  font-weight: 500;
  font-size: 0.9rem;
}

@media (max-width: 576px) {
  .content p {
    font-size: 0.75rem;
  }
}

.bgTransparent a {
  color: #333333;
}

.bgTransparent p {
  color: #333333;
}