.heading {
  font-family: var(--AppFont);
  font-weight: bold;
  padding-left: 7px;
  color: var(--cmn-text-color-dark);
  border-left: 4px solid var(--cmn-text-color-dark);
}

@media (max-width: 576px) {
  .heading {
    padding-left: 5px;
    border-left: 5px solid var(--cmn-text-color-dark);
    font-size: calc(1.1rem + .3vw);
  }
}

.mainImg {
  transition: opacity 0.2s ease-in-out;
}

.fadeOut {
  opacity: 0.5;
}

.fadeIn {
  opacity: 1;
}

.smallIMGContainer {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.smallIMG {
  width: 18.5%;
  margin-right: 1.5%;
}

.smallIMG:last-child {
  margin-right: 0;
}

.dataDates {
  display: flex;
}

.dataDates p {
  font-family: var(--AppFont);
  color: var(--cmn-text-color-ash);
  font-weight: 500;
  margin-bottom: 20px;
}

@media (max-width: 576px) {
  .dataDates p {
    font-size: 0.8rem;
    margin: 13px 0px;
  }
}

.dataDates p:first-child {
  margin-right: 20px;
}

.table {
  width: 88%;
  margin-left: 12%;
}

@media (max-width: 576px) {
  .table {
    width: 100%;
    margin-left: 0%;
  }
}

.table tr {
  border-bottom: 2px solid var(--cmn-text-color-ash);
}

@media (max-width: 576px) {
  .table tr {
    border-bottom: 1px solid var(--cmn-text-color-ash);
  }
}

.table tr td:first-child {
  width: 45%;
  padding: 18px 20px 18px 40px;
  color: var(--cmn-text-color-ash);
  font-weight: bold;
  font-size: 1.1rem;
}

@media (max-width: 576px) {
  .table tr td:first-child {
    width: 40%;
    padding: 14px 0px 14px 25px;
    font-size: 0.8rem;
  }
}

.table tr td:last-child {
  width: 55%;
  color: var(--cmn-text-color-ash);
  font-weight: 500;
  font-size: 1.1rem;
}

@media (max-width: 576px) {
  .table tr td:last-child {
    width: 60%;
    font-size: 0.8rem;
  }
}

.address {
  display: flex;
  justify-content: start;
  align-items: center;
}

.address span {
  font-family: var(--AppFont);
  position: relative;
  margin-right: 4px;
}

.address span::after {
  content: ">";
  margin-left: 2px;
}

.address span:last-child::after {
  content: "";
}

.requestButton {
  width: 90%;
  margin-left: 10%;
  text-align: center;
}

@media (max-width: 576px) {
  .requestButton {
    width: 100%;
    margin-left: 0%;
    text-align: center;
  }
}

.requestButton .inquiryBtn {
  background-color: var(--cmn-orange-shed);
  color: #fff;
  font-family: var(--AppFont);
  font-weight: bold;
  font-size: 1.3rem;
  padding: 16px 100px;
  outline: none;
  border: none;
  margin: 50px 0px;
  transform: scale(1);
  transition: transform 0.5s ease;
}

@media (max-width: 576px) {
  .requestButton .inquiryBtn {
    font-size: 1rem;
    padding: 14px 0;
    width: 100%;
    white-space: nowrap;
    margin: 30px 0px;
    margin-bottom: 10px;
  }
}

.requestButton .inquiryBtn:hover {
  transform: scale(1.05);
}

.BG_GRAY {
  background-color: var(--cmn-gray-light);
  padding: 50px 0px;
}

@media (max-width: 576px) {

  .BG_GRAY {
    padding: 30px 0px;
  }
}

.description {
  font-family: var(--AppFont);
  font-weight: 500;
  color: #666666;
  font-size: 1.1rem;
  margin-bottom: 0;
  padding: 10px;
}

@media (max-width: 576px) {
  .description {
    font-size: 0.9rem;
    padding: 0px;
  }
}


.seeAllLink {
  font-family: var(--AppFont);
  text-align: end;
  margin-left: auto;
  display: flex;
  align-items: end;
  text-decoration: none;
  color: var(--cmn-text-color-ash);
  font-weight: 500;
}

.animalItemCard {
  width: 19% !important;
  flex: 0 0 auto;
}

@media (max-width: 576px) {
  .animalItemCard {
    width: auto !important;
    flex: 0 0 auto;
    display: inline-block !important;
    margin-bottom: 10px;
  }
}

.mobileDescriptionCollaps {
  height: auto;
  max-height: 450px;
  overflow: hidden;
  position: relative;
  padding-bottom: 50px !important;
}

.mobileDescriptionUnCollaps {
  max-height: auto;
}

.expandButton {
  position: absolute;
  top: 360px;
  width: 100%;
  left: 0;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 36%, rgba(255, 255, 255, 0.3) 100%);
  text-align: center;
  height: 90px;
  padding-top: 50px;
}

.expandButton a {
  text-decoration: none;
  color: var(--cmn-text-color-ash);
}

.animalItemRow {
  display: flex;
  /* justify-content: space-between; */
}

@media (max-width: 576px) {
  .animalItemRow {
    width: 100%;
    display: inline-block;
    overflow-x: scroll;
    white-space: nowrap;
  }
}

.bottomFixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  z-index: 99999999999999;
  display: flex;
  padding: 3px;
  justify-content: center;
  align-items: center;
}

.bottomFixed .requestButton {
  width: 80%;
}

.bottomFixed .requestButton .inquiryBtn {
  margin: 0 !important;
  font-size: 0.9rem;
  padding: 12px 0 !important;
}

.floatingFav {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}